import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import generatePDF, { Margin } from 'react-to-pdf';
import { mainListItems } from './listItems';
import { mockMICData } from './mockMICData';

import MicBarChart from '../../components/MIC/Charts/BarChart';
import MICComposedChart from '../../components/MIC/Charts/ComposedChart';
import MICBarChartRitmsMF from '../../components/MIC/Charts/MICBarChartRitmsMF';
import MicPieChart from '../../components/MIC/Charts/PieChart';
import MajorAccomplishments from '../../components/MIC/Table/MajorAccomplishments';
import MajorIncidentsTables from '../../components/MIC/Table/MajorIncidents';

import './mic-report.css';
import { DangerousOutlined, DarkMode } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, Modal, TextField } from '@mui/material';
import {
  CalendarIcon,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SaveButton from '../../components/SubscriptionModal/Mapping/Buttons/Save Button/saveButton';
import { Button, Stack } from 'react-bootstrap';
import toast, { ErrorIcon } from 'react-hot-toast';
import { api } from '../../axiosWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoFetch } from '../../redux/userPreferenceFetch';
import { setUserInfo } from '../../redux/userPreferencesSlice';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import LoginPage from '../Home/LoginPage';
import MobileLoginPage from '../../components/MobileLoginPage/MobileLoginPage';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const app_env = process.env.REACT_APP_ENV;
const getUsername = payload => {
  if (app_env === 'local') {
    return payload?.email?.split('@')[0];
  } else {
    return payload[0]?.name?.split(',')[1] ?? payload[0]?.name?.split(',')[0];
  }
};
export default function MicReportDashboard() {
  const pageTitle = 'Weekly Report Dashboard - Platform Operations';
  //  Change title of the page
  document.title = pageTitle;
  const { userInfo } = useSelector(state => state.userInfo) || {};
  const { instance, accounts } = useMsal();
  const currentUser = accounts ? accounts[0]?.username : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const current_data = searchParams.get('date');
  const [micReportData, setMicReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().startOf('week')['$d']);
  const [endDate, setEndDate] = useState(dayjs().endOf('week')['$d']);
  const [currentDate, setCurrentDate] = React.useState(
    current_data || new Date(),
  );
  const [currentDateMicIncidents, setCurrentDateMicIncidents] = useState(
    current_data || new Date(),
  );
  const [micHandled, setMicHandled] = useState('');
  const [mode, setMode] = useState('light');
  const [requestsHandledOpenClose, setRequestsHandledOpenClose] =
    useState(null);
  const [showCalendar, setShowCalendar] = useState(true);
  const defaultTheme = createTheme({
    palette: {
      mode: mode, // Either 'light' or 'dark'
    },
  });

  const downloadPDF = () => {
    // you can also pass React refs, e.g. `generatePDF(ref, options)`
    generatePDF(() => document.getElementById('pdf-container'), {
      method: 'save',
      filename: 'weekly_report.pdf',
      page: { margin: Margin.MEDIUM },
    });
  };
  const fetchUserData = async () => {
    // Check if a user is logged in.
    setLoading(true);
    try {
      if (accounts && accounts.length > 0) {
        // Get the email of the logged-in user.

        const result = await userInfoFetch(currentUser);

        if (result) {
          const currentPreference = result?.preference ?? [];
          result['currentPreference'] = currentPreference;
          result['username'] = getUsername(accounts);
          // Call the action to fetch user preferences with the email ID.
          dispatch(setUserInfo(result));
        } else {
          // Handle the case where result is undefined or null.
          console.error('Error: userInfoFetch returned undefined or null.');
        }

        //Check if user has set a default theme and view
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDataLocal = async () => {
    setLoading(true);
    try {
      const currentUser = process.env.REACT_APP_CURRENT_USER;
      const result = await userInfoFetch(currentUser);

      if (result) {
        const currentPreference = result?.preference ?? [];
        result['currentPreference'] = currentPreference;
        result['username'] = getUsername({ email: currentUser });

        dispatch(setUserInfo(result));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };
  // Function to toggle between light and dark mode
  const toggleTheme = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const handleDateChange = newValue => {
    const newDate = new Date(newValue['$d']);
    newDate.setDate(newDate.getDate() + 1); // Increment the date by 1
    setShowCalendar(false);
    setCurrentDate(newDate);
  };

  const getFormattedDate = date => {
    const datel = new Date(date);

    return `${datel.getMonth() + 1}/${datel.getDate() - 1}/${datel.getFullYear()}`;
  };

  const fetchMicData = () => {
    // Use a template string to construct the URL
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/mic-reports/live`;
    setCurrentDateMicIncidents(currentDate);
    api
      .get(url)
      .then(res => {
        setMicReportData(res.data);
        setOpenCalendar(false);
      })
      .catch(err => {
        console.error('Error fetching MIC Report data:', err); // Improved error logging
      });
  };
  const fetchMicDataByDate = currentDate => {
    // setLoading(true);
    setCurrentDateMicIncidents(currentDate);
    const datePayload = new Date(currentDate).toISOString();

    // Use a template string to construct the URL
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/mic-reports/dataByDate`;

    api
      .post(url, { date: datePayload })
      .then(res => {
        if (res.data.reports) {
          setMicReportData(res.data.reports);
          toast.success(
            `Showing data for given date ${datePayload?.split('T')[0]}`,
            {
              duration: 6000,
              style: {
                minWidth: 'fit-content', // Increase the minimum width as needed
              },
            },
          );
        } else {
          toast.error(res.data.message, {
            duration: 6000,
            style: {
              minWidth: 'fit-content', // Increase the minimum width as needed
            },
          });
        }
      })
      .catch(err => {
        console.error('Error fetching microphone data:', err); // Improved error logging
        toast.error('No data found for the given date', {
          duration: 6000,
          style: {
            minWidth: '300px', // Increase the minimum width as needed
          },
        });
      })
      .finally(() => {
        // setLoading(false);
        setOpenCalendar(false);
      });
  };

  const fetchRequestsHandledOpenCloseData = date => {
    // setLoading(true);

    // Use a template string to construct the URL
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/mic-reports/request-handled`;

    api
      .get(url)
      .then(res => {
        if (res.data) {
          setRequestsHandledOpenClose(res.data);
        }
      })
      .catch(err => {
        console.error('Error fetching microphone data:', err); // Improved error logging
        toast.error('No data found for the given date');
      });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  // Function to create data format dynamically for breached requests
  const createBreachedRequestsData = data => {
    if (!data.requests_breached) {
      return [];
    }
    return data.requests_breached
      .filter(item => item.has_breached !== 'Total') // Filter out "Total"
      .map(item => ({
        name: item.has_breached === 'true' ? 'SLA Missed' : 'SLA Achieved',
        value: parseInt(item.catalog_request_item_sla_count, 10),
        percentage: item.percentage_of_catalog_request_item_slas,
      }));
  };

  // Function to create data format dynamically for breached incidents
  const createBreachedIncidentsData = data => {
    if (!data.incidents_breached) {
      return [];
    }
    return data.incidents_breached
      .filter(item => item.ticket_sla_breach !== 'Total') // Filter out "Total"
      .map(item => ({
        name: item.ticket_sla_breach === 'true' ? 'SLA Missed' : 'SLA Achieved',
        value: parseInt(item.incident_count, 10),
        percentage: item.percentage_of_incidents,
      }));
  };

  const fetchMajorIncidentsCounts = currentDateMicIncidents => {
    const endDate = dayjs(currentDateMicIncidents).endOf('week')['$d'];
    const startDate = dayjs(currentDateMicIncidents).startOf('week')['$d'];

    api
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/mic-incidents/count/${new Date(startDate).toISOString()}/${new Date(endDate).toISOString()}`,
      )
      .then(res => {
        if (res && res.data) {
          setMicHandled(res.data.mic_handled);
        }
      })
      .catch(err => console.log(err));
  };
  // const breachedRequestsData = createBreachedRequestsData(
  //   mockData?.current_data?.SLA_Breached_Data,
  // );
  // const breachedIncidentsData = createBreachedIncidentsData(
  //   mockData?.current_data?.SLA_Breached_Data,
  // );

  useEffect(() => {
    if (app_env === 'local') {
      fetchUserDataLocal();
    } else {
      fetchUserData();
    }
  }, [dispatch, instance, accounts]);

  useEffect(() => {
    if (userInfo) {
      if (current_data) {
        fetchMicDataByDate(current_data);
      } else {
        fetchMicData();
      }
      fetchRequestsHandledOpenCloseData();
    }
  }, [userInfo, current_data]);

  useEffect(() => {
    fetchMajorIncidentsCounts(currentDateMicIncidents);
  }, [currentDateMicIncidents]);

  if (!micReportData) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              border: '0px solid #f0f0f0',
              padding: '1rem',
              height: '300px',
              borderRadius: '20px',
            }}
          >
            <Typography variant="h4">Loading...</Typography>
            <Typography variant="caption" mb={2}>
              Loading | No data found
            </Typography>

            <Link to="/" style={{ width: '100%', textAlign: 'center' }}>
              Go to service status dashboard
            </Link>
          </Card>
        </Box>
      </ThemeProvider>
    );
  }

  // if (!userInfo || !userInfo.micAccess) {
  //   return loading ? (
  //     <ThemeProvider theme={defaultTheme}>
  //       <Box sx={{ display: 'flex' }}>
  //         <CssBaseline />
  //       </Box>
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           height: '100vh',
  //           flexDirection: 'column',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Card
  //           elevation={0}
  //           sx={{
  //             display: 'flex',
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //             alignItems: 'flex-start',
  //             border: '0px solid #f0f0f0',
  //             padding: '1rem',
  //             height: '300px',
  //             borderRadius: '20px',
  //           }}
  //         >
  //           <Typography variant="h4">Loading | Please wait</Typography>
  //         </Card>
  //       </Box>
  //     </ThemeProvider>
  //   ) : (
  //     <ThemeProvider theme={defaultTheme}>
  //       <Box sx={{ display: 'flex' }}>
  //         <CssBaseline />
  //       </Box>
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           height: '100vh',
  //           flexDirection: 'column',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Card
  //           elevation={0}
  //           sx={{
  //             display: 'flex',
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //             alignItems: 'flex-start',
  //             border: '0px solid #f0f0f0',
  //             padding: '1rem',
  //             height: '300px',
  //             borderRadius: '20px',
  //           }}
  //         >
  //           <Typography variant="h4">401 Unauthorized | No Access</Typography>
  //           <Typography variant="caption" mb={2}>
  //             Please talk to the administrator of Service Status Dashboard to
  //             enable access for you.
  //           </Typography>

  //           <Link to="/" style={{ width: '100%', textAlign: 'center' }}>
  //             Go to service status dashboard
  //           </Link>
  //         </Card>
  //       </Box>
  //     </ThemeProvider>
  //   );
  // }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px',
              backgroundColor: 'white',
              color: 'black',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, color: '#046a38', fontWeight: 'bold' }}
            >
              {pageTitle}
            </Typography>
            <IconButton color="inherit">
              <DownloadIcon onClick={() => downloadPDF()} />
            </IconButton>
            <IconButton color="inherit">
              <CalendarIcon onClick={() => setOpenCalendar(true)} />
            </IconButton>
            <IconButton color="inherit">
              <DarkMode onClick={toggleTheme} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: theme =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <div
              id="pdf-container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              {/* <Typography
                variant="h5"
                component="h1"
                gutterBottom
                align="center"
                style={{ color: '#046a38' }}
              >
                Showing live data for {getWeekRange()}{' '}
                <CalendarMonth onClick={() => setOpenCalendar(true)} />
              </Typography> */}

              <Grid container spacing={3}>
                {/* Info */}

                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.incidents?.active}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      INC Active{' '}
                    </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.incidents?.open}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      INC Open{' '}
                    </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.incidents?.closed}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      INC Closed{' '}
                    </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micHandled}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      MIC Handled{' '}
                    </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.requests?.active}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      {' '}
                      RITM Active
                    </label>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.requests?.open}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      RITM Open{' '}
                    </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      className={
                        mode === 'light'
                          ? 'metric-numbers'
                          : 'metric-numbers-dark'
                      }
                    >
                      {micReportData.current_data?.requests?.closed}
                    </label>
                    <label
                      className={
                        mode === 'light' ? 'tile-label' : 'tile-label-dark'
                      }
                    >
                      RITM Closed{' '}
                    </label>
                  </Paper>
                </Grid>
                {/* Requests Handled Opened & Closed */}
                <Grid item xs={12} md={8} lg={8}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    {/* <label className="tile-label">
                      Requests Handled Opened & Closed
                    </label> */}

                    <MicBarChart
                      title={`Requests Handled Opened & Closed`}
                      data={requestsHandledOpenClose}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '1.5rem',
                      minHeight: '400px',
                    }}
                  >
                    <MICComposedChart
                      title={'RITMs by CSP'}
                      data={micReportData?.csp_data}
                    />
                  </Paper>
                </Grid>
                {/* Requests SLA */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MicPieChart
                      title={'Requests'}
                      data={createBreachedRequestsData(
                        micReportData?.sla_breached_data,
                      )}
                    />
                  </Paper>
                </Grid>
                {/* Incidents SLA */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MicPieChart
                      title={'Incidents'}
                      data={createBreachedIncidentsData(
                        micReportData?.sla_breached_data,
                      )}
                    />
                  </Paper>
                </Grid>
                {/* RITMs by CSP */}

                {/* RITMs Opened by MF */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '1.5rem',
                      minHeight: '400px',
                    }}
                  >
                    <MICBarChartRitmsMF
                      title={'RITMs Opened by MF '}
                      data={micReportData?.all_member_firms}
                    />
                  </Paper>
                </Grid>
                {/* Major Incidents */}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100%',
                      borderRadius: '1.5rem',
                    }}
                  >
                    <h4
                      style={{
                        color: '#046a38',
                      }}
                    >
                      Major Incidents
                    </h4>
                    <MajorIncidentsTables
                      currentDate={currentDateMicIncidents}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '300px',
                      borderRadius: '1.5rem',
                    }}
                  >
                    <MajorAccomplishments
                      data={micReportData}
                      userInfo={userInfo}
                    />
                    <Modal
                      open={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          top: '60px',
                          right: '0px',
                          // transform: 'translate(-50%, -50%)',

                          width: '350px',
                          height: '150px',
                          borderRadius: '10px',
                          border: 'none',
                          background: '#FFF',
                          boxShadow:
                            ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <Stack style={{ padding: '1rem' }} gap={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              disableFuture={true}
                              label="Date "
                              inputFormat="DD/MM/YYYY" // The date format here
                              onChange={handleDateChange}
                              renderInput={params => <TextField {...params} />}
                              sx={{ margin: 0 }}
                            />
                          </LocalizationProvider>
                          <SaveButton
                            handleSave={() => {
                              fetchMicDataByDate(currentDate);
                            }}
                            mobileStyle={{ color: '#fff', width: '100%' }}
                            text={`Get Data for ${getFormattedDate(currentDate)}`}
                          />{' '}
                        </Stack>
                      </Box>
                    </Modal>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
