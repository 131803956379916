import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { Container } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';

import LoginPage from './LoginPage';

import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import MobileInsights from '../../components/MobileInsights/MobileInsights';
import MobileLoginPage from '../../components/MobileLoginPage/MobileLoginPage';
import NewMobileNavBar from '../../components/Navbar/MobileNavBar/NewMobileNavBar';

import ChatbotLogo from '../../ImagesMisc/ChatBotLogo.png';
import {
  userInfoFetch,
  userThemeDefaultViewFetch,
} from '../../redux/userPreferenceFetch';
import {
  setRawPreferences,
  setUserDefaultThemeView,
  setUserInfo,
} from '../../redux/userPreferencesSlice';
import Insights from '../Insights/Insights';

import './home.css';
import { handleDefaultViewFetch } from '../../components/UserPreferences/utils/handleDefaultViewFetch';
import NavBar from '../../components/Navbar/DesktopNavBar/NavBar';
import { Navigate, useNavigate } from 'react-router-dom';

const app_env = process.env.REACT_APP_ENV;

const WrappedNavBar = () =>
  window.innerWidth > 1514 ? <NavBar /> : <NewMobileNavBar />;

const WrappedHeader = () =>
  window.innerWidth > 940 ? <Header /> : <MobileHeader />;

const WrappedInsights = () =>
  window.innerWidth > 1024 ? <Insights /> : <MobileInsights />;

const deviceWidth = window.innerWidth;

const getUsername = payload => {
  if (app_env === 'local') {
    return payload?.email?.split('@')[0];
  } else {
    return payload[0]?.name?.split(',')[1] ?? payload[0]?.name?.split(',')[0];
  }
};

function Home() {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const currentUser = accounts ? accounts[0]?.username : null;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  document
    .querySelector('html')
    .setAttribute('data-theme', localStorage.getItem('selectedTheme'));
  document
    .querySelector('body')
    .setAttribute('data-theme', localStorage.getItem('selectedTheme'));
  useEffect(() => {
    // Function to update state to current window width
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [deviceWidth]); // Depend on deviceWidth to re-run the effect, but avoid reload

  const fetchUserData = async () => {
    // Check if a user is logged in.
    setLoading(true);
    try {
      if (accounts && accounts.length > 0) {
        // Get the email of the logged-in user.

        const result = await userInfoFetch(currentUser);
        const userDefaultView = await handleDefaultViewFetch(currentUser);
        if (result) {
          const currentPreference = result?.preference ?? [];
          result['currentPreference'] = currentPreference;
          result['username'] = getUsername(accounts);
          // Call the action to fetch user preferences with the email ID.
          dispatch(setUserInfo(result));
          dispatch(setRawPreferences(result.preference));
        } else {
          // Handle the case where result is undefined or null.
          console.error('Error: userInfoFetch returned undefined or null.');
        }

        //Check if user has set a default theme and view
        if (userDefaultView?.theme?.mode && userDefaultView?.view) {
          dispatch(setUserDefaultThemeView(userDefaultView));
          await setTheme({ theme: userDefaultView?.theme?.mode });
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchUserDataLocal = async () => {
    setLoading(true);
    try {
      const currentUser = process.env.REACT_APP_CURRENT_USER;
      const result = await userInfoFetch(currentUser);
      const userDefaultView = await handleDefaultViewFetch(currentUser);
      if (result) {
        const currentPreference = result?.preference ?? [];
        result['currentPreference'] = currentPreference;
        result['username'] = getUsername({ email: currentUser });

        dispatch(setUserInfo(result));
        dispatch(setRawPreferences(result.preference));
      }

      if (userDefaultView?.theme?.mode && userDefaultView?.view) {
        dispatch(setUserDefaultThemeView(userDefaultView));
        await setTheme({ theme: userDefaultView?.theme?.mode });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (app_env === 'local') {
      fetchUserDataLocal();
    } else {
      fetchUserData();
    }
  }, [dispatch, instance, accounts]);

  const setTheme = async ({ theme }) => {
    // Check if theme is set to NA, if yes set theme to light
    const localStorageTheme = localStorage.getItem('selectedTheme');

    if (theme === 'NA' && localStorageTheme === null) {
      document.querySelector('html').setAttribute('data-theme', 'light'); // If not, set the theme in local storage to the theme in user preferences
      document.querySelector('body').setAttribute('data-theme', 'light'); // If not, set the theme in local storage to the theme in user preferences
    } else if (theme === 'NA' && localStorageTheme !== null) {
      document
        .querySelector('html')
        .setAttribute('data-theme', localStorageTheme); // If not, set the theme in local storage to the theme in user preferences
      document
        .querySelector('body')
        .setAttribute('data-theme', localStorageTheme); // If not, set the theme in local storage to the theme in user preferences
    } else if (
      (localStorageTheme === null && theme === 'dark') ||
      theme === 'light'
    ) {
      document.querySelector('html').setAttribute('data-theme', theme); // If not, set the theme in local storage to the theme in user preferences
      document.querySelector('body').setAttribute('data-theme', theme);
      localStorage.setItem('selectedTheme', theme);
    }
    // Check if theme in local storage is same as the theme in user preferences
    else if (localStorageTheme === theme) {
      document.querySelector('html').setAttribute('data-theme', theme); // If not, set the theme in local storage to the theme in user preferences
      document.querySelector('body').setAttribute('data-theme', theme); // If not, set the theme in local storage to the theme in user preferences
    } else {
      document.querySelector('html').setAttribute('data-theme', theme);
      document.querySelector('body').setAttribute('data-theme', theme); // If not, set the theme in local storage to the theme in user preferences
      localStorage.setItem('selectedTheme', theme);
    }
  };

  useEffect(() => {
    const micReportRedirectUrl = localStorage.getItem(
      'ssd-postauth-redirect-url',
    );
    if (
      micReportRedirectUrl &&
      micReportRedirectUrl !== '' &&
      micReportRedirectUrl.length > 0
    ) {
      localStorage.setItem('ssd-postauth-redirect-url', '');
      navigate(micReportRedirectUrl, {
        replace: true,
      });
    }
  }, [navigate]);

  if (app_env === 'local') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <WrappedHeader data-testid="header" />
        <div className="home-container">
          <div>
            <div style={{ filter: loading ? 'blur(5px)' : '' }}>
              <div
                className="container-fluid"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <WrappedInsights />
              </div>
              <div className="container-fluid1-table-data">
                <div className="Api-list-flex" data-testid="navbar">
                  <WrappedNavBar />
                </div>
              </div>
              <div>
                <img
                  src={ChatbotLogo}
                  width="50px"
                  height="50px"
                  className="chatbot-logo-img"
                  alt="chatbot"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          filter: loading ? 'blur(5px)' : '',
        }}
      >
        <AuthenticatedTemplate>
          <WrappedHeader />

          <div className="home-container">
            <div
              className="container-fluid"
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <WrappedInsights />
            </div>
            <div className="container-fluid1-table-data">
              <div className="Api-list-flex" data-testid="navbar">
                <WrappedNavBar />
              </div>
            </div>
            <div>
              <img
                src={ChatbotLogo}
                width="50px"
                height="50px"
                className="chatbot-logo-img"
                alt="chatbot"
              />
            </div>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div>
            <Container
              maxWidth={false}
              style={{
                display: 'flex',
                height: '100vh',
                flexDirection: 'row',
                padding: 'none',
              }}
              className="homePageContainer"
              data-testid="login-page"
            >
              {window.innerWidth > 770 ? <LoginPage /> : <MobileLoginPage />}
            </Container>
          </div>
        </UnauthenticatedTemplate>
      </div>
    );
  }
}

export default Home;
