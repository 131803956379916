import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { NavigationClient } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import InactiveLogout from './components/InactiveLogout/InactiveLogout';
import Home from './pages/Home/Home';
import MicReportDashboard from './pages/MicReport/index';

import './App.css';
import HistoricalDataDashboard from './pages/MicReport/historicalData';
import HistoricalDataList from './pages/MicReport/historyDataList';
import { Toaster } from 'react-hot-toast';
import MicReportComponent from './pages/MicReport/index';

class CustomNavigationClient extends NavigationClient {
  constructor(navigate) {
    super();
    this.navigate = navigate;
  }

  async navigateInternal(url, options) {
    const relativePath = url.replace(window.location.origin, '/');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

function App({ msalInstance }) {
  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <InactiveLogout>
          <SSDashboard msalInstance={msalInstance} />
        </InactiveLogout>
      </MsalProvider>
    </Router>
  );
}

function SSDashboard({ msalInstance }) {
  const navigate = useNavigate();
  // console.log("Testing out prod action with npm ci")
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);
  const THEME = createTheme({
    typography: {
      fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    },
  });

  return (
    <ThemeProvider theme={THEME}>
      <div className="App" data-testid="myapp">
        <Toaster />
        <Routes>
          <Route path="/insights" element={<Home />} />
          <Route path="/mic-report" element={<MicReportComponent />} />
          <Route
            path="/historical-report/:start_date/:end_date"
            element={<HistoricalDataDashboard />}
          />
          <Route
            path="/historical-report-list/"
            element={<HistoricalDataList />}
          />
          <Route path="/*" element={<Navigate to="/insights" />} />
          {/* <Route path="/insights" element={<MaintenancePage />} />  */}
        </Routes>
      </div>
      <footer>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
        <a
          href="https://cookienotice.deloitte.com"
          target="_blank"
          rel="noreferrer"
          className="cookie_link"
        >
          {' '}
          Cookies{' '}
        </a>
      </footer>
    </ThemeProvider>
  );
}

export default App;
export { CustomNavigationClient };
