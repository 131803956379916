import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { mainListItems } from './listItems';
import { mockMICData } from './mockMICData';

import MicBarChart from '../../components/MIC/Charts/BarChart';
import MICComposedChart from '../../components/MIC/Charts/ComposedChart';
import MICBarChartRitmsMF from '../../components/MIC/Charts/MICBarChartRitmsMF';
import MicPieChart from '../../components/MIC/Charts/PieChart';
import MajorAccomplishments from '../../components/MIC/Table/MajorAccomplishments';
import MajorIncidentsTables from '../../components/MIC/Table/MajorIncidents';

import './mic-report.css';
import { DangerousOutlined, DarkMode } from '@mui/icons-material';
import { Card, Modal, TextField } from '@mui/material';
import {
  CalendarIcon,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SaveButton from '../../components/SubscriptionModal/Mapping/Buttons/Save Button/saveButton';
import { Button, Stack } from 'react-bootstrap';
import toast, { ErrorIcon } from 'react-hot-toast';
import { api } from '../../axiosWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoFetch } from '../../redux/userPreferenceFetch';
import { setUserInfo } from '../../redux/userPreferencesSlice';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import LoginPage from '../Home/LoginPage';
import MobileLoginPage from '../../components/MobileLoginPage/MobileLoginPage';
import MicReportDashboard from './micdashboard';
import LoginPageMic from '../Home/LoginPageMic';
import { getAppURL, getEnvProdCheck } from '../../utils/utils';

export default function MicReportComponent() {
  const pageTitle = 'Weekly Report Dashboard - Platform Operations';
  //  Change title of the page
  document.title = pageTitle;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <AuthenticatedTemplate>
        <MicReportDashboard />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>
          <Container
            maxWidth={false}
            style={{
              display: 'flex',
              height: '100vh',
              flexDirection: 'row',
              padding: 'none',
            }}
            className="homePageContainer"
            data-testid="login-page"
          >
            {window.innerWidth > 770 ? <LoginPageMic /> : <LoginPageMic />}
          </Container>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}
